<template>
  <v-container>
    <RepMaxDialog
      :exercise="selectedForEdit"
      :show="show1RM"
      @repmax_done="closeRepmax"
      @repmax_cancel="show1RM = false"
    />

    <BaseScreenHeader
      title="Search Exercises"
      screenInfo="EX-005"
      :showBack="true"
    />

    <v-sheet color="charcoal" class="mx-0 mb-10">
      <v-card class="transparent" flat>
        <v-card-actions :class="textSizeXSmall + ' pl-0 py-0'">
        </v-card-actions>
        <template>
          <v-card-text class="text-center text-uppercase py-1 px-0">
            <span class="pa-1 pl-0" v-for="letter in alphabet" :key="letter">
              <v-btn
                :small="!isPhone"
                :x-small="isPhone"
                class="mt-2"
                :color="color(letter)"
                @click="reload(letter)"
                :title="'Exercises Starting With: ' + letter"
                >{{ letter }}
              </v-btn>
            </span>
          </v-card-text>
        </template>
        <v-card-text
          :class="textSizeXSmall + ' pt-2 pl-1 text-center pb-0 silver--text'"
        >
          Dificulty: {{ dificulty }}
        </v-card-text>
        <v-card-text class="text-center silver--text px-0 pt-0 pb-2">
          <v-icon large title="ALL" color="silver" @click="dificulty = 'All'">
            mdi-checkbox-multiple-blank
          </v-icon>
          <v-icon
            v-for="(item, index) in dificultyList"
            :key="index"
            large
            :title="'Dificulty: ' + item.name"
            :color="dificultyColor(item.name)"
            @click="dificulty = item.name"
          >
            mdi-checkbox-blank
          </v-icon>
        </v-card-text>
        <v-select
          dark
          filled
          multiple
          clearable
          chips
          v-model="exerciseTypes"
          :items="exerciseTypesList"
          label="Exercise Type"
          hint="What is the
        exercise type?"
          persistent-hint
          item-text="name"
          item-value="name"
          prepend-inner-icon="mdi-sort"
          class="success--text"
          item-color="success"
          :menu-props="{
            closeOnClick: true,
            maxHeight: 200,
            transition: 'fab-transition'
          }"
        />
        <v-select
          dark
          ref="muscles"
          multiple
          filled
          clearable
          v-model="muscles"
          :items="musclesList"
          label="Muscles Worked"
          chips
          hint="Start typing to search & select a muscle above"
          persistent-hint
          item-text="display"
          item-value="id"
          prepend-inner-icon="mdi-arm-flex"
          class="success--text"
          item-color="success"
          :menu-props="{
            closeOnClick: true,
            maxHeight: 350,
            transition: 'fab-transition'
          }"
        />
        <v-select
          dark
          ref="equipment"
          filled
          clearable
          multiple
          v-model="equipment"
          :items="equipmentList"
          label="Equipment Used"
          chips
          hint="Start typing to search & select equipment above"
          persistent-hint
          item-text="name"
          item-value="id"
          prepend-inner-icon="mdi-dumbbell"
          class="success--text"
          item-color="success"
          :menu-props="{
            closeOnClick: true,
            maxHeight: 350,
            transition: 'fab-transition'
          }"
        />
        <v-card-actions class="px-0 mt-2">
          <BaseActionButton
            dark
            color="silver"
            text
            label="Reset"
            icon="mdi-undo-variant"
            @clickedThis="reset"
            class="ml-n2"
          />
          <v-spacer />
          <BaseActionButton
            color="progressActive"
            text
            label="Search Now"
            icon="mdi-magnify"
            @clickedThis="loadPage"
          />
        </v-card-actions>
      </v-card>
      <v-sheet class="charcoal pb-0 px-0 mt-5">
        <v-row dense>
          <v-col
            v-for="item in exercises"
            :key="item.name"
            cols="12"
            md="6"
            xl="4"
          >
            <ExerciseDisplayTile
              :exercise="item"
              :isSmall="isPhone"
              @repmax="collectOneRepMax"
              @muscle_click="muscleClick"
              @equipment_click="equipmentClick"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>

    <BaseLoading :loaded="loaded" label="Loading Exercises" />

    <LoadingDialog
      :show="!loaded && currentPage == 0"
      title="LOADING Exercises..."
      color="accent"
    />
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const ExerciseDisplayTile = () =>
  import('@/components/pageutil/ExerciseDisplayTile.vue')
const RepMaxDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/RepMaxDialog.vue')
const LoadingDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Loading.vue')

export default {
  name: 'SearchExercises',
  components: { ExerciseDisplayTile, RepMaxDialog, LoadingDialog },
  mixins: [util],
  data: () => ({
    exercises: [],
    loadedList: [],
    musclesList: [],
    equipmentList: [],
    glossary: [],
    dificultyList: [],
    exerciseTypes: [],
    exerciseTypesList: [],
    muscles: [],
    equipment: [],
    sortBy: 'name',
    letter: 'all',
    show1RM: false,
    selectedForEdit: {},
    dificulty: 'All',
    search: null,
    searchLen: null,

    metric: false,
    firstLoad: true,
    weight: 0,
    reps: 0,
    variations: 'Yes',
    loaded: false,
    refresh: false,
    favoritesOnly: false,
    searching: false,
    favoritesNum: 0,
    showFilters: false,
    previousParams: null,
    debouncedScroll: null,
    debounce: null,
    pagesize: 1000,
    currentPage: 0,
    num_exercises: 0,
    searchIn: ['name', 'alternate_name', 'dificulty'],
    bottom: false
  }),
  beforeMount() {
    if (!this.loggedIn) this.$router.push('/login')
    this.alphabet = this.getAlphabet
    this.loadGlossary()
    this.loadMusclesDefinition()
    this.loadEquipmentDefinition()
  },
  mounted() {
    this.getExerciseCount()
    this.loaded = true
  },
  computed: {
    ...appConfig
  },

  methods: {
    reset() {
      this.letter = 'all'
      this.exerciseTypes = []
      this.muscles = []
      this.equipment = []
      this.exercises = []
      this.loadedList = []
    },
    color(letter) {
      if (this.letter == letter) return 'accent'
      else return 'paper'
    },
    reload(letter) {
      this.letter = letter
    },
    muscleClick(value) {
      this.muscles = value.id
      this.loadPage()
    },
    equipmentClick(value) {
      this.equipment = value
    },
    closeRepmax() {
      this.show1RM = false
      window.scrollTo(0, 0)
    },
    collectOneRepMax(value) {
      this.selectedForEdit = value
      this.show1RM = true
    },
    loadGlossary() {
      {
        return axios
          .get(this.baseURL + '/util/glossary/all', {})
          .then(response => {
            if (response.status == 200) {
              this.glossary = response.data.data

              this.dificultyList = this.glossary.filter(function(item) {
                return item.category == 'Difficulty'
              })

              this.exerciseTypesList = this.glossary.filter(function(item) {
                return item.category == 'Exercise Types'
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadMusclesDefinition() {
      {
        return axios
          .get(this.baseURL + '/util/muscles/all', {})
          .then(response => {
            if (response.status == 200) {
              this.musclesList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadEquipmentDefinition() {
      {
        return axios
          .get(this.baseURL + '/util/equipment/all', {})
          .then(response => {
            if (response.status == 200) {
              this.equipmentList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    getExerciseCount() {
      {
        return axios
          .get(this.baseURL + '/exercises/count', {})
          .then(response => {
            if (response.status == 200) {
              this.num_exercises = response.data.data['num_exercises']
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadPage() {
      this.loaded = false

      const urlParams = new URLSearchParams()
      urlParams.append('letter', this.letter)
      urlParams.append('dificulty', this.dificulty)
      urlParams.append('types', this.exerciseTypes)
      urlParams.append('muscles', this.muscles)
      urlParams.append('equipment', this.equipment)

      return axios
        .get(this.baseURL + '/exercises/advancedsearch', { params: urlParams })
        .then(response => {
          if (response.status == 200) {
            let newResponse = response.data.data
            this.exercises = newResponse

            this.loadedList = this.exercises.slice(0)

            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>

<template>
  <v-container>
    <BaseScreenHeader
      title="Manage Variations"
      width="90%"
      screenInfo="EX-007"
    />

    <template v-if="loaded">
      <v-card-title class="paper--text">
        {{ exercise.name }}
      </v-card-title>
      <v-card-actions>
        <v-autocomplete
          dark
          filled
          color="success"
          item-color="success"
          ref="exercises"
          v-model="selectedExercises"
          :items="exercises"
          label="All Exercises"
          chips
          multiple
          deletable-chips
          hint="Search for an exercise"
          persistent-hint
          item-text="name"
          item-value="id"
          prepend-inner-icon="mdi-arm-flex"
          :menu-props="{
            closeOnContentClick: true,
            closeOnClick: true,
            maxHeight: 250,
            transition: 'fab-transition'
          }"
        />
        <v-spacer />
        <BaseActionButton
          dark
          v-if="selectedExercises.length > 0"
          label="Add"
          large
          title="Add Variation"
          icon="mdi-plus-circle-outline"
          plain
          color="paper"
          @clickedThis="addVariations"
        />
      </v-card-actions>
      <v-card-title :class="textSizeSmall + ' paper--text pt-6'">
        Current Variations
      </v-card-title>
      <v-sheet class="rounded transparent charcoalTile">
        <v-card-text
          v-for="(variation, index) in variations"
          :key="index"
          :class="textSizeXSmall + '  silver--text link py-1 mb-2'"
          @click="$router.push('/exercises/' + variation.id)"
        >
          <v-icon
            v-if="xvy"
            class="mt-n1 silver--text"
            @click="markForDelete(variation)"
            @click.native.stop
          >
            mdi-delete-forever
          </v-icon>
          {{ variation.name }}
        </v-card-text>
      </v-sheet>
    </template>

    <v-dialog v-model="deleteVariationDialog" persistent width="500">
      <v-card align="stretch" elevation="20">
        <v-sheet color="charcoal" class="silver--text" height="60">
          <v-card-title class="title"> Delete Variation? </v-card-title>
        </v-sheet>
        <v-card-title color="" :class="textSizeXSmall + ' accent--text'">
          {{ selectedForDelete.name }}
        </v-card-title>
        <v-card-subtitle style="min-height: 200px" class="body-1 py-4">
          Are you sure you want to delete this variation?
        </v-card-subtitle>
        <v-divider />
        <v-card-actions>
          <BaseActionButton
            icon="mdi-close"
            color="info"
            label="Cancel"
            :large="!isPhone"
            plain
            @clickedThis="cancelDelete"
          />
          <v-spacer />
          <BaseActionButton
            icon="mdi-delete-circle-outline"
            label="Yes, Delete"
            :large="!isPhone"
            plain
            @clickedThis="deleteVariation(selectedForDelete)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  components: {},
  mixins: [util],
  data: () => ({
    exercise: {},
    exercises: [],
    variations: [],
    selectedForDelete: {},
    selectedExercises: [],
    deleteVariationDialog: false,
    loaded: false
  }),
  beforeMount() {
    this.loadExercise()
  },
  mounted() {},
  validations: {},
  computed: {
    ...appConfig,
    ...builder
  },
  methods: {
    markForDelete(variation) {
      this.selectedForDelete = variation
      this.deleteVariationDialog = true
    },
    cancelDelete() {
      this.deleteVariationDialog = false
      this.selectedForDelete = {}
    },
    deleteVariation(variation) {
      let url = '/exercises/variations/'
      return axios
        .delete(this.baseURL + url + variation.id + '/' + this.exercise.id, {})
        .then(response => {
          if (response.status == 200) {
            this.loadExercise()
            this.deleteVariationDialog = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    addVariations() {
      let url = '/exercises/variations'
      return axios
        .put(this.baseURL + url, {
          variations: this.selectedExercises,
          parent: this.exercise.id
        })
        .then(response => {
          if (response.status == 200) {
            this.selectedExercises = []
            this.loadExercise()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    loadVariations() {
      let url = '/exercises/variations/'
      return axios
        .get(this.baseURL + url + this.exercise.id, {})
        .then(response => {
          if (response.status == 200) {
            this.variations = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadExercise() {
      {
        let url = '/exercises/view/'
        return axios
          .get(this.baseURL + url + this.$route.params.exerciseid, {})
          .then(response => {
            if (response.status == 200) {
              this.exercise = response.data.data
              this.loadVariations()
              this.loadExercises()
              this.loaded = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadExercises() {
      this.loaded = false
      return axios
        .put(this.baseURL + '/exercises/filter', {
          search: 'variations',
          variations_for: this.exercise.id
        })
        .then(response => {
          if (response.status == 200) {
            this.exercises = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>

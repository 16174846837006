<template>
  <v-container>
    <RepMaxDialog
      :exercise="selectedForEdit"
      :show="show1RM"
      @repmax_done="closeRepmax"
      @repmax_cancel="show1RM = false"
    />

    <v-row>
      <v-col cols="12">
        <v-card class="charcoal mx-auto mt-5 mb-10" elevation="1">
          <BaseScreenHeader
            title="Incomplete Exercises"
            screenInfo="EX-011"
            :showBack="true"
          />

          <v-card-text class="pb-0 px-1 pt-1">
            <v-card-actions class="pt-0">
              <BaseSearch
                dark
                :items="loadedList"
                :label="loadedList.length + ' Exercises'"
                @search_results="setResults"
                @searching="isSearching"
                :searchFor="search"
                :searchIn="['name', 'alternate_name', 'dificulty']"
              />
            </v-card-actions>
            <v-row dense>
              <v-col
                class="mt2"
                v-for="item in exercises"
                :key="item.name"
                cols="12"
                md="6"
                xl="4"
              >
                <ExerciseDisplayTile
                  :exercise="item"
                  :isSmall="isPhone"
                  :showactions="false"
                  @deleted="exerciseDeleted"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <BaseLoading :loaded="loaded" label="Loading Exercises" />
        </v-card>
      </v-col>
    </v-row>
    <LoadingDialog
      :show="!loaded && currentPage == 0"
      title="LOADING Exercises..."
      color="accent"
    />
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const ExerciseDisplayTile = () =>
  import('@/components/pageutil/ExerciseDisplayTile.vue')
const RepMaxDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/RepMaxDialog.vue')
const LoadingDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Loading.vue')
import debounce from 'lodash/debounce'

export default {
  name: 'IncompleteExercises',
  components: { ExerciseDisplayTile, RepMaxDialog, LoadingDialog },
  mixins: [util],
  data: () => ({
    exercises: [],
    loadedList: [],
    musclesList: [],
    equipmentList: [],
    dificultyList: [],
    primary: 0,
    sortBy: 'name',
    show1RM: false,
    byEquipment: false,
    selectedForEdit: {},
    searchMode: '',
    dificulty: 'All',
    all: false,
    search: null,
    searchLen: null,
    equipment: null,
    metric: false,
    firstLoad: true,
    weight: 0,
    reps: 0,
    filterCardio: 'No',
    filterAll: 'Yes',
    filterBodyweight: 'No',
    filterResistance: 'No',
    filterOlympic: 'No',
    filterPowerlifting: 'No',
    filterCalisthenics: 'No',
    variations: 'Yes',
    loaded: true,
    refresh: false,
    showStartsWith: false,
    showAllFilters: false,
    showFilters: false,
    sortDesc: false,
    favoritesOnly: false,
    searching: false,
    favoritesNum: 0,
    showDetails: false,
    previousParams: null,
    alphabet: [],
    letter: 'all',
    debouncedScroll: null,
    debounce: null,
    pagesize: 50,
    currentPage: 0,
    totalCount: 100, //fetch from API
    bottom: false
  }),
  beforeMount() {
    if (!this.loggedIn) this.$router.push('/login')
    this.alphabet = this.getAlphabet
    this.loadGlossary()
    this.loadMusclesDefinition()
    this.loadEquipmentDefinition()
  },
  mounted() {
    this.loadPage()
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },

  watch: {
    bottom(bottom) {
      if (bottom && !this.favoritesOnly && !this.searching) {
        //this.currentPage++
        //this.loadPage()
      }
    }
  },
  computed: {
    favoritesLabel() {
      let start = 'Favorites Only'
      let end = this.favoritesNum > 0 ? '(' + this.favoritesNum + ')' : ''
      return start + end
    },
    itemsCount() {
      return this.exercises.length
    },
    numberOfPages() {
      return Math.ceil(this.exercises.length / this.itemsPerPage)
    },
    ...appConfig
  },
  methods: {
    loadAll() {
      this.refresh = true
      this.currentPage = 0
      if (this.all) {
        this.pagesize = 1000
      } else {
        this.pagesize = 50
      }
      this.loadPage()
    },
    resetSearchParams() {
      this.dificulty = 'All'
      this.search = ''
      this.searchMode = ''
      this.currentPage = 0
    },
    setSearchParams(search) {
      this.dificulty = search
      this.search = search
      this.searchMode = 'dificulty'
      this.currentPage = 0
    },
    setResults(value) {
      this.exercises = value
    },
    isSearching(value) {
      this.searching = value == 'Yes'
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    resetFindBy() {
      if (this.firstLoad) {
        this.firstLoad = false
        return
      }
      if (this.byEquipment) {
        this.primary = null
      } else {
        this.equipment = null
      }
      this.loadPage()
    },
    muscleClick(value) {
      this.primary = value.id
      this.loadPage()
    },
    equipmentClick(value) {
      this.equipment = value
    },
    exerciseDeleted() {
      this.search = null
      this.loadPage()
    },
    color(letter) {
      if (this.letter == letter) return 'accent'
    },
    viewDetails(value) {
      this.$router.push({
        name: 'viewexercise',
        params: { exerciseid: value.id }
      })
    },
    reload(letter) {
      this.favoritesOnly = false
      this.letter = letter
      this.loadPage()
    },
    toggleFavorites() {
      if (this.favoritesOnly) {
        this.exercises = this.exercises.filter(function(item) {
          return item.is_favorite == 'Yes'
        })
        this.favoritesNum = this.exercises.length
      } else {
        this.exercises = this.loadedList
        this.favoritesNum = 0
      }
    },
    closeRepmax() {
      this.show1RM = false
      window.scrollTo(0, 0)
    },
    collectOneRepMax(value) {
      this.selectedForEdit = value
      this.show1RM = true
    },
    loadGlossary() {
      {
        return axios
          .get(this.baseURL + '/util/glossary/exercise', {})
          .then(response => {
            if (response.status == 200) {
              this.glossary = response.data.data

              this.theforces = this.glossary.filter(function(item) {
                return item.category == 'Force'
              })
              this.mechanics = this.glossary.filter(function(item) {
                return item.category == 'Mechanics'
              })
              this.utility = this.glossary.filter(function(item) {
                return item.category == 'Utility'
              })
              this.dificultyList = this.glossary.filter(function(item) {
                return item.category == 'Dificulty'
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadMusclesDefinition() {
      {
        return axios
          .get(this.baseURL + '/util/muscles/all', {})
          .then(response => {
            if (response.status == 200) {
              this.musclesList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadEquipmentDefinition() {
      {
        return axios
          .get(this.baseURL + '/util/equipment/all', {})
          .then(response => {
            if (response.status == 200) {
              this.equipmentList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    reloadPageByMuscle() {
      this.currentPage = 0
      this.loadPage()
    },
    reloadPageByEquipment() {
      this.currentPage = 0
      this.loadPage()
    },
    loadPage() {
      const urlParams = new URLSearchParams()
      urlParams.append('search', this.letter)
      urlParams.append('cardio', this.filterCardio)
      urlParams.append('bodyweight', this.filterBodyweight)
      urlParams.append('resistance', this.filterResistance)
      urlParams.append('olympic', this.filterOlympic)
      urlParams.append('powerlifting', this.filterPowerlifting)
      urlParams.append('calisthenics', this.filterCalisthenics)
      urlParams.append('variations', this.variations)
      urlParams.append('primary', this.primary)
      urlParams.append('equipment', this.equipment)
      urlParams.append('all', this.filterAll)
      urlParams.append('byEquipment', this.byEquipment ? 'Yes' : 'No')

      let keepScrolling =
        this.previousParams == null ||
        this.previousParams.toString() == urlParams.toString()
      if (!keepScrolling) this.currentPage = 0

      return axios
        .get(this.baseURL + '/exercises/incomplete', { params: urlParams })
        .then(response => {
          if (response.status == 200) {
            let newResponse = response.data.data

            if (keepScrolling && !this.refresh) {
              this.exercises = this.exercises.concat(newResponse)
            } else {
              this.exercises = newResponse
            }

            this.loadedList = this.exercises.slice(0)
            this.previousParams = urlParams

            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>

<template>
  <v-container>
    <BaseScreenHeader title="Exercises" screenInfo="EX-002" :showBack="true" />
    <span
      v-if="scrollPosition <= 100"
      class="leftTools charcoal charcoalTileMenu fabsearch"
    >
      <v-btn
        v-if="!aiMode"
        color="transparent silver--text "
        fab
        dark
        class="elevation-0 btn-fix "
        :retain-focus-on-click="false"
        @click="toggleFavorites"
      >
        <v-icon color="accent" class="ml-2" large>
          {{ favoritesOnly ? 'mdi-heart' : 'mdi-heart-outline' }}
        </v-icon>
      </v-btn>
      <v-btn
        color="transparent silver--text  "
        fab
        dark
        class="elevation-0 btn-fix "
        :retain-focus-on-click="false"
        @click="toggleSearch"
      >
        <v-icon class="ml-2" large>
          {{ showSearching ? 'mdi-chevron-left' : 'mdi-magnify' }}
        </v-icon>
      </v-btn>
    </span>
    <v-icon
      v-if="scrollPosition <= 50 && loaded && !aiMode"
      large
      color="paper"
      :class="isPhone ? 'addBtnPhone' : 'addBtn'"
      @click="goTo('/exercises/add')
        
      "
    >
      mdi-plus-circle-outline
    </v-icon>
    <span :class="isPhone ? 'searchBoxPhone' : 'searchBox'">
      <v-sheet
        rounded
        v-if="showSearching && scrollPosition <= 50"
        class="charcoalTileMenu transparent  pa-2"
      >
        <v-card-actions class="pa-0 pt-2">
          <v-text-field
            autofocus
            dark
            filled
            clearable
            :color="!loaded ? 'silver' : 'progressActive'"
            :label="'Search ' + num_exercises + '  Exercises'"
            v-model="search"
            prepend-inner-icon="mdi-arm-flex"
            single-line
            hide-details
            @click:clear="debouncedSearch"
            @keydown.enter="debouncedSearch"
          />
          <v-icon
            class="ml-2"
            :ripple="false"
            color="paper"
            x-large
            title="Search Exercises"
            @click="debouncedSearch"
          >
            mdi-magnify
          </v-icon>
        </v-card-actions>
        <v-card-text
          v-if="aiMode"
          class="silver--text text-cener text-body-2 pa-0 mt-1"
        >
          Before using BRUH AI, search to see if the exercise already exists!
        </v-card-text>
        <v-card-actions class="pa-0 mt-2 ">
          <BaseActionButton
            v-if="!aiMode"
            class=" silver--text ml-n2"
            icon="mdi-magnify-plus-outline"
            label="Advanced Search"
            title="Use Advanced Search"
            text
            :large="!isPhone"
            @clickedThis="
              $router.push({
                path: '/searchexercises'
              })
            "
          />
          <v-spacer />
          <BaseActionButton
            v-if="search != null && search.length >= 5"
            class="text-h6"
            color="cyan"
            icon="mdi-plus-circle-outline"
            label="BRUH AI"
            title="Use Bruh AI to add a new exercise"
            text
            large
            @clickedThis="
              $router.push({
                path: '/exercise/autoadd/' + search
              })
            "
          />
        </v-card-actions>
      </v-sheet>
    </span>

    <div :class="showSearching ? 'searchResults' : 'searchResultsNosearch'">
      <v-sheet v-if="mounted" class="pb-2 transparent">
        <v-card-actions class="paper--text pa-0 mt-2 ">
          <v-spacer />
          <v-checkbox
            v-show="!aiMode"
            dark
            color="progressActive"
            class="mt-2 py-0 px-2 mb-n4"
            false-value="No"
            true-value="Yes"
            off-icon="mdi-expand-all-outline"
            on-icon="mdi-collapse-all-outline"
            v-model="variations"
            :label="
              variations == 'Yes' ? 'Exclude Variations' : 'Include Variations'
            "
            @change="loadPage"
          />
        </v-card-actions>

        <v-row dense class="mt-8">
          <v-col
            class="pa-0 px-1"
            v-for="item in exercises"
            :key="item.name"
            cols="12"
            md="6"
            xl="4"
          >
            <ExerciseDisplayTile
              :exercise="item"
              @repmax="collectOneRepMax"
              @muscle_click.self="muscleClick"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </div>
    <BaseLoading :loaded="loaded" label="Loading" class="mt-2" />
    <LoadingDialog
      :show="!loaded && currentPage == 0 && search == null && loadNum == 0"
      title="LOADING Exercises..."
      color="progressActive"
    />
    <RepMaxDialog
      :exercise="selectedForEdit"
      :show="show1RM"
      @repmax_done="closeRepmax"
      @repmax_cancel="show1RM = false"
    />
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const ExerciseDisplayTile = () =>
  import('@/components/pageutil/ExerciseDisplayTile.vue')
const RepMaxDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/RepMaxDialog.vue')
const LoadingDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Loading.vue')
import debounce from 'lodash/debounce'

export default {
  name: 'ListExercises',
  components: { ExerciseDisplayTile, RepMaxDialog, LoadingDialog },
  mixins: [util],
  data: () => ({
    exercises: [],
    loadedList: [],
    musclesList: [],
    equipmentList: [],
    primary: 0,
    sortBy: 'name',
    show1RM: false,
    byEquipment: false,
    selectedForEdit: {},
    dificulty: 'All',
    all: false,
    search: null,
    searchLen: null,
    equipment: null,
    metric: false,
    firstLoad: true,
    weight: 0,
    reps: 0,
    variations: 'Yes',
    loaded: false,
    refresh: false,
    favoritesOnly: false,
    searching: false,
    favoritesNum: 0,
    previousParams: null,
    debouncedScroll: null,
    debounce: null,
    pagesize: 50,
    currentPage: 0,
    num_exercises: 0,
    loadNum: 0,
    bottom: false,
    showSearching: false,
    mounted: false,
    scrollPosition: 0
  }),
  beforeMount() {
    if (!this.loggedIn) this.$router.push('/login')
    this.alphabet = this.getAlphabet
    this.loadNum = 0
    this.getExerciseCount()
    this.loadPage()
    this.loadGlossary()
    this.loadMusclesDefinition()
    this.loadEquipmentDefinition()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 20)
    window.addEventListener('scroll', this.handleScroll)
    if (this.aiMode) {
      this.showSearching = true
      this.search = ''
    }
    this.mounted = true
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  props: {
    aiMode: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    aiMode(val) {
      if (val) this.showSearching = true
    },
    bottom(bottom) {
      if (bottom && !this.favoritesOnly && !this.searching) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    favoritesLabel() {
      let start = 'Favorites Only'
      let end = this.favoritesNum > 0 ? '(' + this.favoritesNum + ')' : ''
      return start + end
    },
    itemsCount() {
      return this.exercises.length
    },
    ...appConfig
  },
  methods: {
    toggleSearch() {
      this.showSearching = !this.showSearching
      if (this.showSearching) this.search = ''
    },
    debouncedSearch() {
      this.searching = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.loadPage()
      }, 50)
    },
    setResults(value) {
      this.exercises = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.scrollPosition =
        window.pageYOffset ||
        window.scrollY ||
        document.body.scrollTop ||
        document.documentElement.scrollTop

      this.bottom = this.bottomVisible()
    },
    goTo(path) {
      if (this.$route.path != path) {
        this.$router.push(path)
      } else this.$router.go()
    },

    muscleClick(value) {
      this.primary = value.id
      this.goTo('/search/bymuscle/' + value.id)
    },
    equipmentClick(value) {
      this.equipment = value
    },
    exerciseDeleted() {
      this.search = null
      this.loadPage()
    },
    toggleFavorites() {
      this.favoritesOnly = !this.favoritesOnly
      if (this.favoritesOnly) this.search = 'favorites'
      else this.search = ''
      this.loadPage()
    },
    closeRepmax() {
      this.show1RM = false
      window.scrollTo(0, 0)
    },
    collectOneRepMax(value) {
      this.selectedForEdit = value
      this.show1RM = true
    },
    loadGlossary() {
      {
        return axios
          .get(this.baseURL + '/util/glossary/exercise', {})
          .then(response => {
            if (response.status == 200) {
              this.glossary = response.data.data

              this.theforces = this.glossary.filter(function(item) {
                return item.category == 'Force'
              })
              this.mechanics = this.glossary.filter(function(item) {
                return item.category == 'Mechanics'
              })
              this.utility = this.glossary.filter(function(item) {
                return item.category == 'Utility'
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadMusclesDefinition() {
      {
        return axios
          .get(this.baseURL + '/util/muscles/all', {})
          .then(response => {
            if (response.status == 200) {
              this.musclesList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadEquipmentDefinition() {
      {
        return axios
          .get(this.baseURL + '/util/equipment/all', {})
          .then(response => {
            if (response.status == 200) {
              this.equipmentList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    getExerciseCount() {
      {
        return axios
          .get(this.baseURL + '/exercises/count', {})
          .then(response => {
            if (response.status == 200) {
              this.num_exercises = response.data.data['num_exercises']
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadPage() {
      this.loaded = false
      let searchTerm =
        this.search == null || this.search == '' ? 'all' : this.search

      const urlParams = new URLSearchParams()
      urlParams.append('search', searchTerm)
      urlParams.append('variations', this.variations)

      let keepScrolling =
        (this.previousParams == null ||
          this.previousParams.toString() == urlParams.toString()) &&
        !this.searching

      if (!keepScrolling) {
        this.currentPage = 0
        this.refresh = true
      }

      return axios
        .get(
          this.baseURL +
            '/exercises/simple/scroll/' +
            this.currentPage +
            '/' +
            this.pagesize,
          { params: urlParams }
        )
        .then(response => {
          if (response.status == 200) {
            let newResponse = response.data.data

            if (keepScrolling) {
              this.exercises = this.exercises.concat(newResponse)
            } else {
              this.exercises = newResponse
            }

            this.loadedList = this.exercises.slice(0)
            this.previousParams = urlParams
            this.favoritesNum = 0
            if (this.favoritesOnly) this.favoritesNum = this.exercises.length
            this.loadNum++
            this.searching = false
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
<style scoped>
.addBtn {
  position: fixed;
  right: 5px;
  top: 90px;
  z-index: 100;
}

.addBtnPhone {
  position: fixed;
  right: 5px;
  top: 75px;
  z-index: 100;
}

.leftTools {
  position: fixed;
  left: 5px;
  opacity: 0.9;
  top: 90px;
}

.searchBox {
  position: absolute;
  width: 95%;
  top: 5px;
}

.searchBoxPhone {
  position: absolute;
  width: 95%;
  top: 85px;
}

.searchResults {
  margin-top: 225px;
}

.searchResultsNosearch {
  margin-top: 55px;
}

.fabsearch {
  border-radius: 0px 20px 20px 0px;
}
.enlarged-checkbox {
  transform: scale(1.5);
  transform-origin: left;
}
</style>

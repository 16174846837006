<template v-if="exercises">
  <v-container>
    <v-form v-if="loaded" ref="exerciseForm" name="exerciseForm">
      <BaseScreenHeader
        title="Edit Exercise"
        screenInfo="EX-004"
        :showBack="true"
        :showNav="true"
      />
      <v-card-text class="px-1">
        <v-card-actions class="pa-0 mb-2">
          <BaseLinkButton
            icon="mdi-link-variant"
            label="Manage Variations"
            color="paper"
            plain
            class="pl-0"
            :to="'/exercises/manage/variations/' + exercise.id"
          />
          <v-spacer />
          <BaseActionButton
            v-bind="$attrs"
            text
            large
            color="paper"
            :disabled="$v.$anyError"
            @clickedThis="saveExercise"
          />
        </v-card-actions>
        <v-row>
          <v-col cols="12" md="3">
            <v-sheet color="transparent" class="pa-2">
              <v-card-actions class="silver--text pl-0">
                Exercise Type <v-divider class="ml-2 charcoal lighten-2 " />
              </v-card-actions>
              <v-switch
                dark
                ref="resistance"
                false-value="No"
                true-value="Yes"
                color="accent"
                v-model="exercise.resistance"
              >
                <template v-slot:label>
                  Resistance
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['resistance'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="bodyweight"
                false-value="No"
                true-value="Yes"
                label="Bodyweight ?"
                color="accent"
                v-model="exercise.bodyweight"
              >
                <template v-slot:label>
                  Bodyweight
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['bodyweight'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="calisthenics"
                false-value="No"
                true-value="Yes"
                label="Calisthenics ?"
                color="accent"
                v-model="exercise.calisthenics"
              >
                <template v-slot:label>
                  Calisthenics
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['calisthenics'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="cardio"
                false-value="No"
                true-value="Yes"
                label="Cardio ?"
                color="accent"
                v-model="exercise.cardio"
              >
                <template v-slot:label>
                  Cardio
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['cardio'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="bilateral"
                false-value="No"
                true-value="Yes"
                label="Bilateral ?"
                color="accent"
                v-model="exercise.bilateral"
              >
                <template v-slot:label>
                  Bilateral
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['bilateral'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="track_distance"
                false-value="No"
                true-value="Yes"
                color="accent"
                v-model="exercise.track_distance"
              >
                <template v-slot:label>
                  Track Distance
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['track_distance'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="track_time"
                false-value="No"
                true-value="Yes"
                color="accent"
                v-model="exercise.track_time"
              >
                <template v-slot:label>
                  Track Time
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['track_time'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="olympic"
                false-value="No"
                true-value="Yes"
                color="accent"
                v-model="exercise.olympic"
              >
                <template v-slot:label>
                  Olympic Lift
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['olympic'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="powerlifting"
                false-value="No"
                true-value="Yes"
                label="Powerlifting ?"
                color="accent"
                v-model="exercise.powerlifting"
              >
                <template v-slot:label>
                  Powerlifting
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['powerlifting'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-card-actions class="silver--text pl-0 pb-0">
                Visibility <v-divider class="ml-2 charcoal lighten-2 " />
              </v-card-actions>
              <v-switch
                dark
                ref="public"
                false-value="No"
                true-value="Yes"
                label="Public ?"
                color="success"
                v-model="exercise.public"
              >
                <template v-slot:label>
                  Public
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['public'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                v-if="xvy"
                ref="approved"
                false-value="No"
                true-value="Yes"
                label="Approved?"
                color="success"
                v-model="exercise.approved"
              >
                <template v-slot:label>
                  Approved
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['approved'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
            </v-sheet>
          </v-col>
          <v-col cols="12" md="8">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  dark
                  v-model="name"
                  ref="name"
                  :error-messages="nameErrors"
                  :counter="70"
                  label="Name"
                  prepend-icon="mdi-dumbbell"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                />
              </v-col>
              <v-col cols="12">
                <v-card-text
                  class="py-0 mt-n2 pl-8 caption progressActive--text"
                >
                  <span class="silver--text">Generated Code: </span>
                  {{ code }}
                </v-card-text>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dark
                  v-model="alternate_name"
                  ref="alternate_name"
                  :counter="200"
                  label="Alternate Name"
                  prepend-icon="mdi-dumbbell"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-select
                  dark
                  color="success"
                  item-color="success"
                  v-model="dificulty"
                  :items="dificultyList"
                  label="Dificulty"
                  hint="What is the exercise dificulty?"
                  persistent-hint
                  item-text="name"
                  item-value="value"
                  prepend-icon="mdi-chevron-right-box"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dark
                  v-model="description"
                  ref="description"
                  :error-messages="descriptionErrors"
                  :counter="500"
                  :rows="3"
                  label="Description"
                  prepend-icon="mdi-text"
                  @input="$v.description.$touch()"
                  @blur="$v.description.$touch()"
                />
              </v-col>
            </v-row>
            <v-row dense v-if="xvy">
              <v-col cols="12">
                <v-text-field
                  dark
                  v-model="exercise.image"
                  ref="image"
                  label="Image"
                  prepend-icon="mdi-image-edit"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <h5 class="silver--text pb-1 pl-1">Detailed Instructions</h5>
            <tiptap-vuetify
              v-model="instructions"
              :extensions="extensions"
              placeholder="Write your exercise instructions here.."
            />
          </v-col>
        </v-row>
        <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
          Target Muscles
          <v-divider class="ml-2" />
        </v-card-actions>
        <v-row dense>
          <v-col cols="12">
            <v-autocomplete
              dark
              clearable
              ref="primary"
              filled
              v-model="primary"
              :items="musclesList"
              label="Primary Muscles"
              multiple
              chips
              deletable-chips
              hint="What are the primary muscles targetted?"
              persistent-hint
              item-text="display"
              item-value="id"
              append-icon="mdi-arm-flex"
              color="success"
              item-color="success"
              :menu-props="{
                closeOnClick: true,
                maxHeight: 250,
                transition: 'fab-transition'
              }"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.display }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ primary.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              dark
              clearable
              v-model="secondary"
              filled
              ref="secondary"
              :items="musclesList"
              label="Secondary Muscles"
              multiple
              chips
              deletable-chips
              hint="What are the secondary muscles targetted?"
              persistent-hint
              item-text="display"
              item-value="id"
              append-icon="mdi-arm-flex"
              color="success"
              item-color="success"
              :menu-props="{
                closeOnClick: true,
                maxHeight: 250,
                transition: 'fab-transition'
              }"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.display }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ secondary.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              dark
              clearable
              v-model="stabilizers"
              filled
              ref="stabilizers"
              :items="musclesList"
              label="Stabilizing Muscles"
              multiple
              chips
              deletable-chips
              hint="What are the stabilizing muscles?"
              persistent-hint
              item-text="display"
              item-value="id"
              append-icon="mdi-arm-flex"
              color="success"
              item-color="success"
              :menu-props="{
                closeOnClick: true,
                maxHeight: 250,
                transition: 'fab-transition'
              }"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.display }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ stabilizers.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
          Equipment
          <v-divider class="ml-2" />
        </v-card-actions>
        <v-row dense>
          <v-col cols="12">
            <v-autocomplete
              dark
              clearable
              filled
              ref="equipment"
              v-model="equipment"
              :items="equipmentList"
              label="Equipment Needed"
              multiple
              chips
              deletable-chips
              hint="What is the equipment needed to perform this exercise?"
              persistent-hint
              item-text="name"
              item-value="id"
              append-icon="mdi-jump-rope"
              color="success"
              item-color="success"
              :menu-props="{
                closeOnClick: true,
                maxHeight: 250,
                transition: 'fab-transition'
              }"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ equipment.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
          Force Type: {{ theforceType.name }}
          <v-divider class="ml-2" />
        </v-card-actions>
        <v-row dense class="pl-2">
          <v-col
            cols="6"
            md="3"
            v-for="(theforce, index) in theforces"
            :key="index"
          >
            <v-card
              width="150"
              height="150"
              class="elevation-5 mb-3 title"
              :color="tileColor(theforce.name === theforceType.name)"
              @click="setTheForce(theforce)"
            >
              <v-card-actions
                :class="textColor(theforce.name === theforceType.name)"
              >
                {{ theforce.name }}
              </v-card-actions>
              <v-card-actions class="pt-10">
                <v-icon
                  :color="iconColor(theforce.name === theforceType.name)"
                  x-large
                >
                  {{ theforce.icon }}
                </v-icon>
                <v-spacer />
                <v-icon v-if="theforce.name === theforceType.name" color="paper"
                  >mdi-check-circle
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
          <blockquote
            v-html="theforceType.details"
            class="silver--text caption pl-2"
          />
        </v-row>
        <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
          Mechanics
          <v-divider class="ml-2" />
        </v-card-actions>
        <v-row class="pl-2" dense>
          <v-col cols="6" md="3" v-for="mech in mechanics" :key="mech.name">
            <v-card
              width="150"
              height="150"
              @click="setMechanics(mech)"
              class="elevation-5 mb-3 title"
              :color="tileColor(mech.name === mechanicsType.name)"
            >
              <v-card-actions
                :class="textColor(mech.name === mechanicsType.name)"
              >
                {{ mech.name }}
              </v-card-actions>
              <v-card-actions class="pt-10">
                <v-icon
                  :color="iconColor(mech.name === mechanicsType.name)"
                  x-large
                >
                  {{ mech.icon }}
                </v-icon>
                <v-spacer />
                <v-icon
                  v-if="mech.name === mechanicsType.name"
                  color="paper"
                  right
                  >mdi-check-circle
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
          <blockquote
            v-html="mechanicsType.details"
            class="silver--text caption pl-2"
          />
        </v-row>
        <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
          Utility
          <v-divider class="ml-2" />
        </v-card-actions>
        <v-row class="pl-2" dense>
          <v-col cols="6" md="3" v-for="util in utility" :key="util.name">
            <v-card
              width="150"
              height="150"
              @click="setUtility(util)"
              class="elevation-5 mb-3 title"
              :color="tileColor(util.name === utilityType.name)"
            >
              <v-card-actions
                :class="textColor(util.name === utilityType.name)"
              >
                {{ util.name }}
              </v-card-actions>
              <v-card-actions class="pt-10">
                <v-icon
                  :color="iconColor(util.name === utilityType.name)"
                  x-large
                >
                  {{ util.icon }}
                </v-icon>
                <v-spacer />
                <v-icon
                  v-if="util.name === utilityType.name"
                  color="paper"
                  right
                  >mdi-check-circle
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
          <blockquote
            v-html="utilityType.details"
            class="silver--text caption pl-2"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <BaseActionButton
          plain
          :large="!isPhone"
          v-if="canEditExercise"
          dark
          v-bind="$attrs"
          :disabled="$v.$anyError"
          class="charcoal paper--text"
          @clickedThis="saveExercise"
        />
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak,
  HorizontalRule
} from 'tiptap-vuetify'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'
import tooltips from '@/json/tooltips.json'

export default {
  components: { TiptapVuetify },
  mixins: [validationMixin, util],
  props: {
    exerciseid: String
  },
  data: () => ({
    tooltips: tooltips,
    exercise: {},
    theforces: [],
    mechanics: [],
    utility: [],
    mechanicsType: {},
    utilityType: {},
    theforceType: {},
    name: '',
    alternate_name: '',
    oldname: '',
    dificulty: '',
    description: '',
    instructions: '',
    primary: [],
    secondary: [],
    stabilizers: [],
    equipment: [],
    musclesList: [],
    equipmentList: [],
    dificultyList: [],
    glossary: {},
    showSuccess: false,
    loaded: false,
    switchNum: 0,
    extensions: [
      Paragraph,
      Bold,
      Italic,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3, 4, 5, 6]
          }
        }
      ],
      HorizontalRule,
      Link,
      ListItem,
      BulletList,
      OrderedList,
      HardBreak
    ]
  }),
  beforeMount() {
    this.loadGlosary()
    this.loadEquipment()
    this.loadMusclesDefinition()
  },
  mounted() {},
  validations: {
    name: {
      required
    },
    description: {
      required
    }
  },
  computed: {
    code() {
      return this.generateCode(this.name)
    },
    canEditExercise() {
      return this.xvy || this.exercise.author == this.userid
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Exercise name is required.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.description.$dirty) return errors
      !this.$v.description.required && errors.push('Description is required.')
      return errors
    },
    ...appConfig
  },
  methods: {
    tileColor(selected) {
      return selected ? 'charcoal charcoalTileMenu' : 'charcoal'
    },
    textColor(selected) {
      return selected ? 'paper--text' : 'silver--text'
    },
    iconColor(selected) {
      return selected ? 'paper' : 'silver'
    },
    addMore() {
      this.showSuccess = false
      this.$v.$reset()
      this.$refs.name.focus()
    },
    setTheForce(what) {
      this.theforceType = what
    },
    setMechanics(what) {
      this.mechanicsType = what
    },
    setUtility(what) {
      this.utilityType = what
    },
    saveExercise() {
      window.scrollTo(0, 0)
      return axios
        .put(this.baseURL + '/exercises', {
          exerciseid: this.exercise.id,
          author: this.exercise.author,
          name: this.name,
          alternate_name: this.alternate_name,
          dificulty: this.dificulty,
          description: this.description,
          instructions: this.instructions,
          primary: this.primary,
          secondary: this.secondary,
          stabilizers: this.stabilizers,
          equipment: this.equipment,
          theforce: this.theforceType.name,
          mechanics: this.mechanicsType.name,
          utility: this.utilityType.name,
          public: this.exercise.public,
          resistance: this.exercise.resistance,
          cardio: this.exercise.cardio,
          bilateral: this.exercise.bilateral,
          track_distance: this.exercise.track_distance,
          track_time: this.exercise.track_time,
          olympic: this.exercise.olympic,
          powerlifting: this.exercise.powerlifting,
          calisthenics: this.exercise.calisthenics,
          approved: this.exercise.approved,
          image: this.exercise.image,
          code: this.code,
          bodyweight: this.exercise.bodyweight
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Exercise was succesfully updated.')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadMusclesDefinition() {
      {
        return axios
          .get(this.baseURL + '/util/muscles/all', {})
          .then(response => {
            if (response.status == 200) {
              this.musclesList = response.data.data
              this.loadExercise()
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadEquipment() {
      {
        return axios
          .get(this.baseURL + '/util/equipment/all', {})
          .then(response => {
            if (response.status == 200) {
              this.equipmentList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadGlosary() {
      return axios
        .get(this.baseURL + '/util/glossary/all', {})
        .then(response => {
          if (response.status == 200) {
            this.glossary = response.data.data

            this.theforces = this.glossary.filter(function(item) {
              return item.category == 'Force'
            })
            this.mechanics = this.glossary.filter(function(item) {
              return item.category == 'Mechanics'
            })
            this.utility = this.glossary.filter(function(item) {
              return item.category == 'Utility'
            })
            this.dificultyList = this.glossary.filter(function(item) {
              return item.category == 'Difficulty'
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadExercise() {
      {
        return axios
          .get(this.baseURL + '/exercises/view/' + this.exerciseid, {})
          .then(response => {
            if (response.status == 200) {
              this.exercise = response.data.data
                this.name = this.exercise.name
              this.alternate_name = this.exercise.alternate_name
              this.description = this.exercise.description
              this.dificulty = this.exercise.dificulty
              this.instructions = this.exercise.instructions

              if (this.exercise.mechanics != null) {
                let selMechanics = this.exercise.mechanics
                let mechanics = this.mechanics.filter(function(item) {
                  return item.name == selMechanics
                })
                this.setMechanics(mechanics[0])
              }
              if (this.exercise.utility != null) {
                let selUtility = this.exercise.utility
                let utility = this.utility.filter(function(item) {
                  return item.name == selUtility
                })
                this.setUtility(utility[0])
              }

              if (this.exercise.mechanics != null) {
                let selForce = this.exercise.force
                let force = this.theforces.filter(function(item) {
                  return item.name == selForce
                })
                this.setTheForce(force[0])
              }

              let muscles = JSON.parse(this.exercise.muscles)
              this.equipment = JSON.parse(this.exercise.equipment)
              this.primary = muscles
                ? muscles.filter(function(item) {
                    return item.type == 'Primary'
                  })
                : null

              this.secondary = muscles
                ? muscles.filter(function(item) {
                    return item.type == 'Secondary'
                  })
                : null

              this.stabilizers = muscles
                ? muscles.filter(function(item) {
                    return item.type == 'Stabilizers'
                  })
                : null
              this.loaded = true
              this.switchNum++
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>

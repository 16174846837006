<template>
  <v-container class="px-0">
    <v-form ref="exerciseForm" name="exerciseForm">
      <BaseScreenHeader title="Add Exercise" screenInfo="EX-001" />
      <v-card-text class="pb-0 silver--text">
        <v-row dense>
          <v-col cols="12" md="3">
            <v-sheet color="transparent" class="pa-2 elevation-1">
              <v-card-actions class="silver--text py-0 mb-n2 pl-0">
                Exercise Type <v-divider class="ml-2 charcoal lighten-2 " />
              </v-card-actions>
              <v-switch
                dark
                ref="resistance"
                false-value="No"
                true-value="Yes"
                color="accent"
                class="pa-0 mb-0"
                v-model="resistance"
              >
                <template v-slot:label>
                  Resistance
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['resistance'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="bodyweight"
                false-value="No"
                true-value="Yes"
                label="Bodyweight ?"
                color="accent"
                class="pa-0 my-0"
                v-model="bodyweight"
              >
                <template v-slot:label>
                  Bodyweight
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['bodyweight'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="calisthenics"
                false-value="No"
                true-value="Yes"
                label="Calisthenics ?"
                color="accent"
                class="pa-0 my-0"
                v-model="calisthenics"
              >
                <template v-slot:label>
                  Calisthenics
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['calisthenics'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="cardio"
                false-value="No"
                true-value="Yes"
                label="Cardio ?"
                color="accent"
                class="pa-0 my-0"
                v-model="cardio"
              >
                <template v-slot:label>
                  Cardio
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['cardio'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="bilateral"
                false-value="No"
                true-value="Yes"
                label="Bilateral ?"
                class="pa-0 my-0"
                color="accent"
                v-model="bilateral"
              >
                <template v-slot:label>
                  Bilateral
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['bilateral'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="track_distance"
                false-value="No"
                true-value="Yes"
                color="accent"
                class="pa-0 my-0"
                v-model="track_distance"
              >
                <template v-slot:label>
                  Track Distance
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['track_distance'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-switch
                dark
                ref="track_time"
                false-value="No"
                true-value="Yes"
                color="accent"
                class="pa-0 my-0"
                v-model="track_time"
              >
                <template v-slot:label>
                  Track Time
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['track_time'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>

              <v-card-actions class="silver--text pl-0 pb-0">
                Visibility <v-divider class="ml-2 charcoal lighten-2" />
              </v-card-actions>
              <v-switch
                dark
                ref="public"
                false-value="No"
                true-value="Yes"
                label="Public ?"
                v-model="ispublic"
                color="success"
              >
                <template v-slot:label>
                  Public
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="silver--text">
                        mdi-help
                      </v-icon>
                    </template>
                    <span>
                      {{ tooltips['public'] }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
            </v-sheet>
          </v-col>
          <v-col cols="12" md="8">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  dark
                  filled
                  v-model="name"
                  ref="name"
                  :error-messages="nameErrors"
                  :counter="70"
                  label="Name"
                  prepend-inner-icon="mdi-dumbbell"
                  @input="$v.name.$touch()"
                  @blur="
                    $v.name.$touch()
                    checkCode()
                  "
                />
              </v-col>
              <v-col cols="12">
                <v-card-text
                  class="py-0 mt-n2 pl-8 caption progressActive--text"
                >
                  <span class="silver--text">Generated Code: </span>
                  {{ code }}
                </v-card-text>
                <v-card-text v-if="!codeUnique" class="py-0 ml-4 caption">
                  <span class="orange--text">
                    Code is not unique, please make sure the exercise does not
                    exist. To continue, change the exercise name.
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-select
                  dark
                  filled
                  v-model="dificulty"
                  :items="dificultyList"
                  label="Dificulty"
                  hint="What is the exercise dificulty?"
                  persistent-hint
                  item-text="name"
                  item-value="value"
                  prepend-inner-icon="mdi-chevron-right-box"
                  class="success--text"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dark
                  filled
                  v-model="description"
                  ref="description"
                  :error-messages="descriptionErrors"
                  :counter="250"
                  :rows="3"
                  label="Description"
                  prepend-inner-icon="mdi-text"
                  @input="$v.description.$touch()"
                  @blur="$v.description.$touch()"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col cols="12">
            <h5 class="silver--text pb-1 pl-1">Detailed Instructions</h5>
            <tiptap-vuetify
              v-model="instructions"
              :extensions="extensions"
              placeholder="Write your exercise instructions here.."
            />
          </v-col>
        </v-row>
        <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
          Target Muscles
          <v-divider class="ml-2 charcoal lighten-2 " />
        </v-card-actions>
        <v-row dense>
          <v-col cols="12">
            <v-autocomplete
              dark
              clearable
              ref="primary"
              filled
              v-model="primary"
              :items="musclesList"
              label="Primary Muscles"
              multiple
              chips
              hint="What are the primary muscles targetted?"
              persistent-hint
              item-text="display"
              item-value="id"
              item-color="success"
              append-icon="mdi-arm-flex"
              color="success"
              :menu-props="{
                closeOnClick: true,
                maxHeight: 250,
                transition: 'fab-transition'
              }"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.display }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ primary.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              dark
              clearable
              v-model="secondary"
              filled
              ref="secondary"
              :items="musclesList"
              label="Secondary Muscles"
              multiple
              chips
              deletable-chips
              hint="What are the secondary muscles targetted?"
              persistent-hint
              item-text="display"
              item-value="id"
              item-color="success"
              append-icon="mdi-arm-flex"
              color="success"
              :menu-props="{
                closeOnClick: true,
                maxHeight: 250,
                transition: 'fab-transition'
              }"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.display }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ secondary.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              dark
              clearable
              v-model="stabilizers"
              filled
              ref="stabilizers"
              :items="musclesList"
              label="Stabilizing Muscles"
              multiple
              chips
              deletable-chips
              hint="What are the stabilizing muscles?"
              persistent-hint
              item-text="display"
              item-value="id"
              item-color="success"
              append-icon="mdi-arm-flex"
              class="success--text"
              color="success"
              :menu-props="{
                closeOnClick: true,
                maxHeight: 250,
                transition: 'fab-transition'
              }"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.display }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ stabilizers.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
          Equipment
          <v-divider class="ml-2" />
        </v-card-actions>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              dark
              clearable
              filled
              ref="equipment"
              v-model="equipment"
              :items="equipmentList"
              label="Equipment Needed"
              multiple
              chips
              deletable-chips
              hint="What is the equipment needed to perform this exercise?"
              persistent-hint
              item-text="name"
              item-value="id"
              item-color="success"
              append-icon="mdi-jump-rope"
              class="success--text"
              color="success"
              :menu-props="{
                closeOnClick: true,
                maxHeight: 250,
                transition: 'fab-transition'
              }"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ equipment.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
          Force Type: {{ theforceType.name }}
          <v-divider class="ml-2" />
        </v-card-actions>
        <v-row dense class="pl-2">
          <v-col
            cols="6"
            md="3"
            v-for="theforce in theforces"
            :key="theforce.name"
          >
            <v-card
              width="150"
              height="150"
              class=" mb-3 title charcoalTile"
              :color="tileColor(theforce.name === theforceType.name)"
              @click="settheforce(theforce)"
            >
              <v-card-actions
                :class="textColor(theforce.name === theforceType.name)"
              >
                {{ theforce.name }}
              </v-card-actions>
              <v-card-actions class="pt-10">
                <v-icon
                  :color="iconColor(theforce.name === theforceType.name)"
                  x-large
                >
                  {{ theforce.icon }}
                </v-icon>
                <v-spacer />
                <v-icon v-if="theforce.name === theforceType.name" color="paper"
                  >mdi-check-circle
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
          <blockquote v-html="theforceType.details" class="caption pl-2" />
        </v-row>
        <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
          Mechanics
          <v-divider class="ml-2" />
        </v-card-actions>
        <v-row class="pl-2" dense>
          <v-col cols="6" md="3" v-for="mech in mechanics" :key="mech.name">
            <v-card
              width="150"
              height="150"
              @click="setMechanics(mech)"
              class="elevation-5 mb-3 title"
              :color="tileColor(mech.name === mechanicsType.name)"
            >
              <v-card-actions
                :class="textColor(mech.name === mechanicsType.name)"
              >
                {{ mech.name }}
              </v-card-actions>
              <v-card-actions class="pt-10">
                <v-icon
                  :color="iconColor(mech.name === mechanicsType.name)"
                  x-large
                >
                  {{ mech.icon }}
                </v-icon>
                <v-spacer />
                <v-icon
                  v-if="mech.name === mechanicsType.name"
                  color="paper"
                  right
                  >mdi-check-circle
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
          <blockquote v-html="mechanicsType.details" class="caption pl-2" />
        </v-row>
        <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
          Utility
          <v-divider class="ml-2" />
        </v-card-actions>
        <v-row class="pl-2" dense>
          <v-col cols="6" md="3" v-for="util in utility" :key="util.name">
            <v-card
              width="150"
              height="150"
              @click="setUtility(util)"
              class="elevation-5 mb-3 title"
              :color="tileColor(util.name === utilityType.name)"
            >
              <v-card-actions
                :class="textColor(util.name === utilityType.name)"
              >
                {{ util.name }}
              </v-card-actions>
              <v-card-actions class="pt-10">
                <v-icon
                  :color="iconColor(util.name === utilityType.name)"
                  x-large
                >
                  {{ util.icon }}
                </v-icon>
                <v-spacer />
                <v-icon
                  v-if="util.name === utilityType.name"
                  color="paper"
                  right
                  >mdi-check-circle
                </v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
          <blockquote v-html="utilityType.details" class="caption pl-2" />
        </v-row>
      </v-card-text>
      <v-card-actions class="pl-0">
        <v-spacer />
        <BaseActionButton
          :disabled="$v.$anyError || !codeUnique"
          label="Add Exercise"
          icon="mdi-plus-circle-outline"
          large
          dark
          plain
          class="paper--text"
          @clickedThis="saveExercise()"
        />
      </v-card-actions>
    </v-form>
  </v-container>
</template>
<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak,
  HorizontalRule
} from 'tiptap-vuetify'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import tooltips from '@/json/tooltips.json'

export default {
  components: { TiptapVuetify },
  mixins: [validationMixin, util],
  data: () => ({
    tooltips: tooltips,
    codeUnique: true,
    theforces: [],
    mechanics: [],
    utility: [],
    mechanicsType: '',
    utilityType: '',
    theforceType: '',
    name: '',
    oldname: '',
    dificulty: '',
    description: '',
    instructions: '',
    resistance: 'Yes',
    bodyweight: 'No',
    cardio: 'No',
    calisthenics: 'No',
    bilateral: 'No',
    track_distance: 'No',
    track_time: 'No',
    ispublic: 'Yes',
    primary: [],
    secondary: [],
    stabilizers: [],
    equipment: [],
    musclesList: [],
    equipmentList: [],
    dificultyList: [],
    glossary: {},
    extensions: [
      Paragraph,
      Bold,
      Italic,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3, 4, 5, 6]
          }
        }
      ],
      HorizontalRule,
      Link,
      ListItem,
      BulletList,
      OrderedList,
      HardBreak
    ]
  }),
  beforeMount() {
    this.loadMusclesDefinition()
    this.loadEquipment()
    this.loadGlossary()
  },
  mounted() {
  },
  validations: {
    name: {
      required
    },
    description: {
      required
    }
  },
  computed: {
    code() {
      return this.generateCode(this.name)
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Exercise name is required.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.description.$dirty) return errors
      !this.$v.description.required && errors.push('Description is required.')
      return errors
    },
    ...appConfig
  },
  methods: {
    tileColor(selected) {
      return selected ? 'charcoal charcoalTileMenu' : 'charcoal'
    },
    textColor(selected) {
      return selected ? 'paper--text' : 'silver--text'
    },
    iconColor(selected) {
      return selected ? 'paper' : 'silver'
    },
    settheforce(name) {
      this.theforceType = name
    },
    setMechanics(name) {
      this.mechanicsType = name
    },
    setUtility(name) {
      this.utilityType = name
    },
    checkCode() {
      {
        return axios
          .get(this.baseURL + '/exercises/checkcode/' + this.code, {})
          .then(response => {
            if (response.status == 200) {
              this.codeUnique = response.data.data.is_unique == 'Yes'
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveExercise() {
      window.scrollTo(0, 0)
      return axios
        .post(this.baseURL + '/exercises', {
          name: this.name,
          dificulty: this.dificulty,
          description: this.description,
          instructions: this.instructions,
          primary: this.primary,
          secondary: this.secondary,
          stabilizers: this.stabilizers,
          equipment: this.equipment,
          theforce: this.theforceType.name,
          mechanics: this.mechanicsType.name,
          utility: this.utilityType.name,
          resistance: this.resistance,
          bodyweight: this.bodyweight,
          track_distance: this.track_distance,
          track_time: this.track_time,
          cardio: this.cardio,
          calisthenics: this.calisthenics,
          bilateral: this.bilateral,
          public: this.ispublic,
          code: this.code
        })
        .then(response => {
          if (response.status == 200) {
            this.oldname = this.name
            this.$refs.exerciseForm.reset()
            this.name = ''
            this.description = ''
            this.instructions = ''
            this.dificulty = ''
            this.primary = []
            this.secondary = []
            this.stabilizers = []
            this.equipment = []
            this.theforceType = ''
            this.mechanicsType = ''
            this.utilityType = ''
            this.toast('Exercise ' + this.oldname + ' was succesfully added.')
            this.$router.push('/exercises')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadMusclesDefinition() {
      {
        return axios
          .get(this.baseURL + '/util/muscles/all', {})
          .then(response => {
            if (response.status == 200) {
              this.musclesList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadEquipment() {
      {
        return axios
          .get(this.baseURL + '/util/equipment/all', {})
          .then(response => {
            if (response.status == 200) {
              this.equipmentList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadGlossary() {
      {
        return axios
          .get(this.baseURL + '/util/glossary/exercise', {})
          .then(response => {
            if (response.status == 200) {
              this.glossary = response.data.data

              this.theforces = this.glossary.filter(function(item) {
                return item.category == 'Force'
              })
              this.mechanics = this.glossary.filter(function(item) {
                return item.category == 'Mechanics'
              })
              this.utility = this.glossary.filter(function(item) {
                return item.category == 'Utility'
              })
              this.dificultyList = this.glossary.filter(function(item) {
                return item.category == 'Difficulty'
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>

<template>
  <v-container>
    <RepMaxDialog
      :exercise="selectedForEdit"
      :show="show1RM"
      @repmax_done="closeRepmax"
      @repmax_cancel="show1RM = false"
    />

    <BaseScreenHeader
      title="View Exercise"
      width="90%"
      screenInfo="EX-003"
      :showBack="true"
      :showNav="true"
    />
    <ExerciseDisplayPage
      v-if="exercise"
      :exercise="exercise"
      :rm="rm"
      :primary="primary"
      :secondary="secondary"
      :stabilizers="stabilizers"
      :equipment="equipment"
      :isSmall="isPhone"
      @repmax="collectOneRepMax"
    />
    <v-card-title :class="textSizeSmall + ' paper--text'">
      Similar Exercises
    </v-card-title>
    <v-card-text
      v-for="(ex, index) in similar"
      :key="index"
      :class="textSizeXSmall + ' silver--text link py-1 mb-2'"
      @click="$router.push('/exercises/view/' + ex.code)"
    >
      <BaseLinkIcon />
      {{ ex.name }}
    </v-card-text>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'

const ExerciseDisplayPage = () =>
  import('@/components/pageutil/ExerciseDisplayPage.vue')
const RepMaxDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/RepMaxDialog.vue')

export default {
  components: { ExerciseDisplayPage, RepMaxDialog },
  mixins: [util],
  name:'ViewExercise',
  data: () => ({
    exercise: {},
    rm: {},
    similar: [],
    glossary: [],
    primary: [],
    secondary: [],
    stabilizers: [],
    equipment: [],
    favorites: [],
    selectedForEdit: {},
    show1RM: false
  }),
  beforeMount() {
    this.reset()
  },
  mounted() {},
  validations: {},
  computed: {
    ...appConfig,
    ...builder,
    isFavorite() {
      var exerciseid = this.exercise.id
      let fav = this.favorites.some(function(item) {
        return item.exerciseid == exerciseid
      })
      return fav
    }
  },
  props: {
    exerciseid: {
      type: String
    }
  },
  watch: {
    exerciseid() {
      this.reset()
    }
  },
  methods: {
    reset() {
      this.scrollToTop()
      this.loadExercise()
      this.loadGlossary()
      if (this.loggedIn) this.loadDetail()
    },
    closeRepmax() {
      this.show1RM = false
      window.scrollTo(0, 0)
    },
    collectOneRepMax(value) {
      this.selectedForEdit = value
      this.show1RM = true
    },
    loadGlossary() {
      {
        return axios
          .get(this.baseURL + '/util/glossary/all', {})
          .then(response => {
            if (response.status == 200) {
              this.glossary = response.data.data

              this.theforces = this.glossary.filter(function(item) {
                return item.category == 'Force'
              })
              this.mechanics = this.glossary.filter(function(item) {
                return item.category == 'Mechanics'
              })
              this.utility = this.glossary.filter(function(item) {
                return item.category == 'Utility'
              })
              this.dificultyList = this.glossary.filter(function(item) {
                return item.category == 'Dificulty'
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadSimilar() {
      {
        let url = '/exercises/similar/'

        return axios
          .get(this.baseURL + url + this.exercise.id, {})
          .then(response => {
            if (response.status == 200) {
              this.similar = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadExercise() {
      {
        let url = '/exercises/view/public/'
        if (this.loggedIn) url = '/exercises/view/'
        return axios
          .get(this.baseURL + url + this.exerciseid, {})
          .then(response => {
            if (response.status == 200) {
              this.exercise = response.data.data
              this.loadSimilar()
              let muscles = JSON.parse(this.exercise.muscles)
              this.equipment = JSON.parse(this.exercise.equipment)
              this.primary = muscles
                ? muscles.filter(function(item) {
                    return item.type == 'Primary'
                  })
                : null
              this.secondary = muscles
                ? muscles.filter(function(item) {
                    return item.type == 'Secondary'
                  })
                : null
              this.stabilizers = muscles
                ? muscles.filter(function(item) {
                    return item.type == 'Stabilizers'
                  })
                : null
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadDetail() {
      return axios
        .get(this.baseURL + '/stats/strength/detail/' + this.exerciseid)
        .then(response => {
          if (response.status == 200) {
            if (response.data.data) this.rm = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>

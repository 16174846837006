<template>
  <v-container>
    <BaseScreenHeader
      title="Manage Variations"
      width="90%"
      screenInfo="WRL-011"
    />

    <template v-if="loaded">
      <v-card-title class="paper--text">
        {{ workout.name }}
      </v-card-title>
      <v-card-actions>
        <v-autocomplete
          dark
          filled
          clearable
          color="success"
          item-color="success"
          ref="workouts"
          v-model="selectedworkouts"
          :items="workouts"
          label="All Workouts"
          chips
          multiple
          deletable-chips
          hint="Select/Search workout variation"
          persistent-hint
          item-text="name"
          item-value="workoutid"
          prepend-inner-icon="mdi-weight-lifter"
          :menu-props="{
            closeOnClick: true,
            maxHeight: 250,
            transition: 'fab-transition'
          }"
        />
      </v-card-actions>
      <v-card-actions class="py-0">
        <v-spacer />
        <BaseActionButton
          v-if="selectedworkouts.length > 0"
          label="Add Variation"
          title="Add Variation"
          icon="mdi-plus-circle-outline"
          plain
          color="silver"
          @clickedThis="addVariations"
        />
      </v-card-actions>
      <template v-if="variations.length > 0">
        <v-card-title :class="textSizeSmall + ' paper--text px-2 pt-4'">
          Current Variations
        </v-card-title>
        <v-card-text
          v-for="(variation, index) in variations"
          :key="index"
          :class="textSizeXSmall + ' silver--text link py-1 mb-2'"
        >
          <v-icon
            v-if="xvy"
            class="mt-n1 orange--text"
            @click="markForDelete(variation)"
            @click.native.stop
          >
            mdi-delete-forever
          </v-icon>
          {{ variation.variation_name }}
        </v-card-text>
      </template>
    </template>

    <v-dialog
      v-model="deleteVariationDialog"
      persistent
      width="500"
      :fullscreen="isPhone"
    >
      <v-card tile class="charcoal d-flex flex-column noscroll">
        <BaseCloseDialogHeader
          heading="Delete Workout Variation?"
          @cancel="cancelDelete"
        />
        <v-card-title :class="textSize + ' px-2 progressActive--text'">
          {{ selectedForDelete.variation_name }}
        </v-card-title>
        <v-card-subtitle
          style="min-height: 200px"
          class="silver--text body-1 px-2 pt-2"
        >
          Are you sure you want to delete this variation?
        </v-card-subtitle>
        <v-spacer />
        <v-card-actions class="px-0">
          <v-spacer />
          <BaseActionButton
            icon="mdi-delete-circle-outline"
            label="Yes, Delete"
            :large="!isPhone"
            plain
            class="charcoal paper--text"
            @clickedThis="deleteVariation(selectedForDelete)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  name: 'ManageWorkoutVariations',
  mixins: [util],
  data: () => ({
    workout: {},
    workouts: [],
    variations: [],
    selectedForDelete: {},
    selectedworkouts: [],
    deleteVariationDialog: false,
    loaded: false
  }),
  beforeMount() {
    this.loadWorkout()
  },
  mounted() {},
  props: {
    workoutid: {
      type: String,
      default: null
    }
  },
  validations: {},
  computed: {
    ...appConfig,
    ...builder
  },
  methods: {
    markForDelete(variation) {
      this.selectedForDelete = variation
      this.deleteVariationDialog = true
    },
    cancelDelete() {
      this.deleteVariationDialog = false
      this.selectedForDelete = {}
    },
    deleteVariation(variation) {
      let url = '/workout/variations/'
      return axios
        .delete(this.baseURL + url + variation.id + '/' + this.workoutid, {})
        .then(response => {
          if (response.status == 200) {
            this.loadWorkout()
            this.deleteVariationDialog = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    addVariations() {
      let url = '/workout/variations'
      return axios
        .post(this.baseURL + url, {
          variations: this.selectedworkouts,
          parent: this.workout.workoutid
        })
        .then(response => {
          if (response.status == 200) {
            this.selectedworkouts = []
            this.loadWorkout()
            this.toast('Succesfully added workout variants.')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    loadVariations() {
      let url = '/workout/variations/'
      return axios
        .get(this.baseURL + url + this.workout.workoutid, {})
        .then(response => {
          if (response.status == 200) {
            this.variations = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadWorkout() {
      {
        let url = '/workout/'
        return axios
          .get(this.baseURL + url + this.$route.params.workoutid, {})
          .then(response => {
            if (response.status == 200) {
              this.workout = response.data.data
              this.loadVariations()
              this.loadWorkouts()
              this.loaded = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadWorkouts() {
      this.loaded = false
      return axios
        .get(
          this.baseURL + '/workouts/forvariants/' + this.workout.workoutid,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            this.workouts = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('RepMaxDialog',{attrs:{"exercise":_vm.selectedForEdit,"show":_vm.show1RM},on:{"repmax_done":_vm.closeRepmax,"repmax_cancel":function($event){_vm.show1RM = false}}}),_c('BaseScreenHeader',{attrs:{"title":"Search Exercises","screenInfo":"EX-005","showBack":true}}),_c('v-sheet',{staticClass:"mx-0 mb-10",attrs:{"color":"charcoal"}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-actions',{class:_vm.textSizeXSmall + ' pl-0 py-0'}),[_c('v-card-text',{staticClass:"text-center text-uppercase py-1 px-0"},_vm._l((_vm.alphabet),function(letter){return _c('span',{key:letter,staticClass:"pa-1 pl-0"},[_c('v-btn',{staticClass:"mt-2",attrs:{"small":!_vm.isPhone,"x-small":_vm.isPhone,"color":_vm.color(letter),"title":'Exercises Starting With: ' + letter},on:{"click":function($event){return _vm.reload(letter)}}},[_vm._v(_vm._s(letter)+" ")])],1)}),0)],_c('v-card-text',{class:_vm.textSizeXSmall + ' pt-2 pl-1 text-center pb-0 silver--text'},[_vm._v(" Dificulty: "+_vm._s(_vm.dificulty)+" ")]),_c('v-card-text',{staticClass:"text-center silver--text px-0 pt-0 pb-2"},[_c('v-icon',{attrs:{"large":"","title":"ALL","color":"silver"},on:{"click":function($event){_vm.dificulty = 'All'}}},[_vm._v(" mdi-checkbox-multiple-blank ")]),_vm._l((_vm.dificultyList),function(item,index){return _c('v-icon',{key:index,attrs:{"large":"","title":'Dificulty: ' + item.name,"color":_vm.dificultyColor(item.name)},on:{"click":function($event){_vm.dificulty = item.name}}},[_vm._v(" mdi-checkbox-blank ")])})],2),_c('v-select',{staticClass:"success--text",attrs:{"dark":"","filled":"","multiple":"","clearable":"","chips":"","items":_vm.exerciseTypesList,"label":"Exercise Type","hint":"What is the\n      exercise type?","persistent-hint":"","item-text":"name","item-value":"name","prepend-inner-icon":"mdi-sort","item-color":"success","menu-props":{
          closeOnClick: true,
          maxHeight: 200,
          transition: 'fab-transition'
        }},model:{value:(_vm.exerciseTypes),callback:function ($$v) {_vm.exerciseTypes=$$v},expression:"exerciseTypes"}}),_c('v-select',{ref:"muscles",staticClass:"success--text",attrs:{"dark":"","multiple":"","filled":"","clearable":"","items":_vm.musclesList,"label":"Muscles Worked","chips":"","hint":"Start typing to search & select a muscle above","persistent-hint":"","item-text":"display","item-value":"id","prepend-inner-icon":"mdi-arm-flex","item-color":"success","menu-props":{
          closeOnClick: true,
          maxHeight: 350,
          transition: 'fab-transition'
        }},model:{value:(_vm.muscles),callback:function ($$v) {_vm.muscles=$$v},expression:"muscles"}}),_c('v-select',{ref:"equipment",staticClass:"success--text",attrs:{"dark":"","filled":"","clearable":"","multiple":"","items":_vm.equipmentList,"label":"Equipment Used","chips":"","hint":"Start typing to search & select equipment above","persistent-hint":"","item-text":"name","item-value":"id","prepend-inner-icon":"mdi-dumbbell","item-color":"success","menu-props":{
          closeOnClick: true,
          maxHeight: 350,
          transition: 'fab-transition'
        }},model:{value:(_vm.equipment),callback:function ($$v) {_vm.equipment=$$v},expression:"equipment"}}),_c('v-card-actions',{staticClass:"px-0 mt-2"},[_c('BaseActionButton',{staticClass:"ml-n2",attrs:{"dark":"","color":"silver","text":"","label":"Reset","icon":"mdi-undo-variant"},on:{"clickedThis":_vm.reset}}),_c('v-spacer'),_c('BaseActionButton',{attrs:{"color":"progressActive","text":"","label":"Search Now","icon":"mdi-magnify"},on:{"clickedThis":_vm.loadPage}})],1)],2),_c('v-sheet',{staticClass:"charcoal pb-0 px-0 mt-5"},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.exercises),function(item){return _c('v-col',{key:item.name,attrs:{"cols":"12","md":"6","xl":"4"}},[_c('ExerciseDisplayTile',{attrs:{"exercise":item,"isSmall":_vm.isPhone},on:{"repmax":_vm.collectOneRepMax,"muscle_click":_vm.muscleClick,"equipment_click":_vm.equipmentClick}})],1)}),1)],1)],1),_c('BaseLoading',{attrs:{"loaded":_vm.loaded,"label":"Loading Exercises"}}),_c('LoadingDialog',{attrs:{"show":!_vm.loaded && _vm.currentPage == 0,"title":"LOADING Exercises...","color":"accent"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
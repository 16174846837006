<template v-if="exercises">
  <v-container>
    <v-form ref="exerciseForm" name="exerciseForm">
      <BaseScreenHeader
        title="Auto Add Exercise"
        screenInfo="EX-013"
        :showBack="true"
        :showNav="true"
      />
      <template v-if="Object.hasOwn(exercise, 'name') && step == 4">
        <v-card-text class="px-1">
          <v-card-actions class="pa-0 mb-2">
            <v-spacer />
            <BaseActionButton
              v-bind="$attrs"
              text
              large
              color="paper"
              :disabled="$v.$anyError"
              @clickedThis="saveExercise"
            />
          </v-card-actions>
          <v-row>
            <v-col cols="12" md="3">
              <v-sheet color="transparent" class="pa-2">
                <v-card-actions class="silver--text pl-0">
                  Exercise Type <v-divider class="ml-2 charcoal lighten-2 " />
                </v-card-actions>
                <v-switch
                  dark
                  ref="resistance"
                  false-value="No"
                  true-value="Yes"
                  color="accent"
                  v-model="exercise.resistance"
                >
                  <template v-slot:label>
                    Resistance
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="silver--text">
                          mdi-help
                        </v-icon>
                      </template>
                      <span>
                        {{ tooltips['resistance'] }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-switch>
                <v-switch
                  dark
                  ref="bodyweight"
                  false-value="No"
                  true-value="Yes"
                  label="Bodyweight ?"
                  color="accent"
                  v-model="exercise.bodyweight"
                >
                  <template v-slot:label>
                    Bodyweight
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="silver--text">
                          mdi-help
                        </v-icon>
                      </template>
                      <span>
                        {{ tooltips['bodyweight'] }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-switch>
                <v-switch
                  dark
                  ref="calisthenics"
                  false-value="No"
                  true-value="Yes"
                  label="Calisthenics ?"
                  color="accent"
                  v-model="exercise.calisthenics"
                >
                  <template v-slot:label>
                    Calisthenics
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="silver--text">
                          mdi-help
                        </v-icon>
                      </template>
                      <span>
                        {{ tooltips['calisthenics'] }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-switch>
                <v-switch
                  dark
                  ref="cardio"
                  false-value="No"
                  true-value="Yes"
                  label="Cardio ?"
                  color="accent"
                  v-model="exercise.cardio"
                >
                  <template v-slot:label>
                    Cardio
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="silver--text">
                          mdi-help
                        </v-icon>
                      </template>
                      <span>
                        {{ tooltips['cardio'] }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-switch>
                <v-switch
                  dark
                  ref="bilateral"
                  false-value="No"
                  true-value="Yes"
                  label="Bilateral ?"
                  color="accent"
                  v-model="exercise.bilateral"
                >
                  <template v-slot:label>
                    Bilateral
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="silver--text">
                          mdi-help
                        </v-icon>
                      </template>
                      <span>
                        {{ tooltips['bilateral'] }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-switch>
                <v-switch
                  dark
                  ref="track_distance"
                  false-value="No"
                  true-value="Yes"
                  color="accent"
                  v-model="exercise.track_distance"
                >
                  <template v-slot:label>
                    Track Distance
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="silver--text">
                          mdi-help
                        </v-icon>
                      </template>
                      <span>
                        {{ tooltips['track_distance'] }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-switch>
                <v-switch
                  dark
                  ref="track_time"
                  false-value="No"
                  true-value="Yes"
                  color="accent"
                  v-model="exercise.track_time"
                >
                  <template v-slot:label>
                    Track Time
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="silver--text">
                          mdi-help
                        </v-icon>
                      </template>
                      <span>
                        {{ tooltips['track_time'] }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-switch>
                <v-switch
                  dark
                  ref="olympic"
                  false-value="No"
                  true-value="Yes"
                  color="accent"
                  v-model="exercise.olympic"
                >
                  <template v-slot:label>
                    Olympic Lift
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="silver--text">
                          mdi-help
                        </v-icon>
                      </template>
                      <span>
                        {{ tooltips['olympic'] }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-switch>
                <v-switch
                  dark
                  ref="powerlifting"
                  false-value="No"
                  true-value="Yes"
                  label="Powerlifting ?"
                  color="accent"
                  v-model="exercise.powerlifting"
                >
                  <template v-slot:label>
                    Powerlifting
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="silver--text">
                          mdi-help
                        </v-icon>
                      </template>
                      <span>
                        {{ tooltips['powerlifting'] }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-switch>
                <v-card-actions class="silver--text pl-0 pb-0">
                  Visibility <v-divider class="ml-2 charcoal lighten-2 " />
                </v-card-actions>
                <v-switch
                  dark
                  ref="public"
                  false-value="No"
                  true-value="Yes"
                  label="Public ?"
                  color="success"
                  v-model="exercise.public"
                >
                  <template v-slot:label>
                    Public
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="silver--text">
                          mdi-help
                        </v-icon>
                      </template>
                      <span>
                        {{ tooltips['public'] }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-switch>
                <v-switch
                  dark
                  v-if="xvy"
                  ref="approved"
                  false-value="No"
                  true-value="Yes"
                  label="Approved?"
                  color="success"
                  v-model="exercise.approved"
                >
                  <template v-slot:label>
                    Approved
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="silver--text">
                          mdi-help
                        </v-icon>
                      </template>
                      <span>
                        {{ tooltips['approved'] }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-switch>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="8">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    dark
                    v-model="exercise.name"
                    ref="name"
                    :error-messages="nameErrors"
                    :counter="70"
                    label="Name"
                    prepend-icon="mdi-dumbbell"
                    @input="calculateCode"
                    @blur="$v.exercise.name.$touch()"
                  />
                </v-col>
                <v-col cols="12">
                  <v-card-text
                    class="py-0 mt-n2 pl-8 caption progressActive--text"
                  >
                    <span class="silver--text">Generated Code: </span>
                    {{ exercise.code }}
                  </v-card-text>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dark
                    v-model="exercise.alternate_name"
                    ref="alternate_name"
                    :counter="200"
                    label="Alternate Name"
                    prepend-icon="mdi-dumbbell"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-select
                    dark
                    color="success"
                    item-color="success"
                    v-model="exercise.difficulty"
                    :items="dificultyList"
                    label="Dificulty"
                    hint="What is the exercise dificulty?"
                    persistent-hint
                    item-text="name"
                    item-value="value"
                    prepend-icon="mdi-chevron-right-box"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    dark
                    v-model="exercise.description"
                    ref="description"
                    :error-messages="descriptionErrors"
                    :counter="500"
                    :rows="3"
                    label="Description"
                    prepend-icon="mdi-text"
                    @input="$v.exercise.description.$touch()"
                    @blur="$v.exercise.description.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row dense v-if="xvy">
                <v-col cols="12">
                  <v-text-field
                    dark
                    v-model="exercise.image"
                    ref="image"
                    label="Image"
                    prepend-icon="mdi-image-edit"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <h5 class="silver--text pb-1 pl-1">Detailed Instructions</h5>
              <tiptap-vuetify
                v-model="exercise.instructionsStr"
                :extensions="extensions"
                placeholder="Write your exercise instructions here.."
              />
            </v-col>
          </v-row>
          <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
            Target Muscles
            <v-divider class="ml-2" />
          </v-card-actions>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                dark
                ref="primary"
                filled
                v-model="primary"
                :items="musclesList"
                label="Primary Muscles"
                multiple
                chips
                deletable-chips
                hint="What are the primary muscles targetted?"
                persistent-hint
                item-text="display"
                item-value="id"
                append-icon="mdi-arm-flex"
                color="success"
                item-color="success"
                :menu-props="{
                  closeOnClick: true,
                  maxHeight: 250,
                  transition: 'fab-transition'
                }"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.display }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ primary.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                dark
                v-model="secondary"
                filled
                ref="secondary"
                :items="musclesList"
                label="Secondary Muscles"
                multiple
                chips
                deletable-chips
                hint="What are the secondary muscles targetted?"
                persistent-hint
                item-text="display"
                item-value="id"
                append-icon="mdi-arm-flex"
                color="success"
                item-color="success"
                :menu-props="{
                  closeOnClick: true,
                  maxHeight: 250,
                  transition: 'fab-transition'
                }"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.display }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ secondary.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                dark
                v-model="stabilizers"
                filled
                ref="stabilizers"
                :items="musclesList"
                label="Stabilizing Muscles"
                multiple
                chips
                deletable-chips
                hint="What are the stabilizing muscles?"
                persistent-hint
                item-text="display"
                item-value="id"
                append-icon="mdi-arm-flex"
                color="success"
                item-color="success"
                :menu-props="{
                  closeOnClick: true,
                  maxHeight: 250,
                  transition: 'fab-transition'
                }"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.display }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ stabilizers.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
            Equipment
            <v-divider class="ml-2" />
          </v-card-actions>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                dark
                filled
                ref="equipment"
                v-model="equipment"
                :items="equipmentList"
                label="Equipment Needed"
                multiple
                chips
                deletable-chips
                hint="What is the equipment needed to perform this exercise?"
                persistent-hint
                item-text="name"
                item-value="id"
                append-icon="mdi-jump-rope"
                color="success"
                item-color="success"
                :menu-props="{
                  closeOnClick: true,
                  maxHeight: 250,
                  transition: 'fab-transition'
                }"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ equipment.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
            Force Type: {{ exercise.theforce }}
            <v-divider class="ml-2" />
          </v-card-actions>
          <v-row dense class="pl-2">
            <v-col
              cols="6"
              md="3"
              v-for="theforce in theforces"
              :key="theforce.name"
            >
              <v-card
                width="150"
                height="150"
                class="elevation-5 mb-3 title"
                :color="tileColor(theforce.name === exercise.theforce)"
                @click="settheforce(theforce)"
              >
                <v-card-actions
                  :class="textColor(theforce.name === exercise.theforce)"
                >
                  {{ theforce.name }}
                </v-card-actions>
                <v-card-actions class="pt-10">
                  <v-icon
                    :color="iconColor(theforce.name === exercise.theforce)"
                    x-large
                  >
                    {{ theforce.icon }}
                  </v-icon>
                  <v-spacer />
                  <v-icon
                    v-if="theforce.name === exercise.theforce"
                    color="paper"
                    >mdi-check-circle
                  </v-icon>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
            Mechanics
            <v-divider class="ml-2" />
          </v-card-actions>
          <v-row class="pl-2" dense>
            <v-col cols="6" md="3" v-for="mech in mechanics" :key="mech.name">
              <v-card
                width="150"
                height="150"
                @click="setMechanics(mech)"
                class="elevation-5 mb-3 title"
                :color="tileColor(mech.name === exercise.mechanics)"
              >
                <v-card-actions
                  :class="textColor(mech.name === exercise.mechanics)"
                >
                  {{ mech.name }}
                </v-card-actions>
                <v-card-actions class="pt-10">
                  <v-icon
                    :color="iconColor(mech.name === exercise.mechanics)"
                    x-large
                  >
                    {{ mech.icon }}
                  </v-icon>
                  <v-spacer />
                  <v-icon
                    v-if="mech.name === exercise.mechanics"
                    color="paper"
                    right
                    >mdi-check-circle
                  </v-icon>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
            Utility
            <v-divider class="ml-2" />
          </v-card-actions>
          <v-row class="pl-2" dense>
            <v-col cols="6" md="3" v-for="util in utility" :key="util.name">
              <v-card
                width="150"
                height="150"
                @click="setUtility(util)"
                class="elevation-5 mb-3 title"
                :color="tileColor(util.name === exercise.utility)"
              >
                <v-card-actions
                  :class="textColor(util.name === exercise.utility)"
                >
                  {{ util.name }}
                </v-card-actions>
                <v-card-actions class="pt-10">
                  <v-icon
                    :color="iconColor(util.name === exercise.utility)"
                    x-large
                  >
                    {{ util.icon }}
                  </v-icon>
                  <v-spacer />
                  <v-icon
                    v-if="util.name === exercise.utility"
                    color="paper"
                    right
                    >mdi-check-circle
                  </v-icon>
                </v-card-actions>
              </v-card>
            </v-col>
            <blockquote
              v-html="utilityType.details"
              class="silver--text caption pl-2"
            />
          </v-row>
        </v-card-text>
      </template>
      <v-card class="charcoal mt-5 py-2" flat rounded>
        <template v-if="step == 0 && !processed">
          <v-card-text class="paper--text text-body-1 text-center pt-0 ">
            <span class="cyan--text text-h5"> Bruh AI </span>
            <v-spacer />
            <span class="silver--text caption">
              can help you set up
            </span>

            <v-spacer />
            <span class="text-uppercase">"{{ exercise_name }}"</span>
          </v-card-text>
          <v-card-actions class=" py-4">
            <v-spacer />
            <v-img
              src="@/assets/BlueBrother.png"
              :width="isPhone ? '220px' : '150px'"
              contain
              alt="Bruh-AI Assistant"
              class="rounded-lg"
            />
            <v-spacer />
          </v-card-actions>
          <v-card-actions class=" pa-0 caption silver--text">
            <v-spacer />
            to continue
            <v-spacer />
          </v-card-actions>
          <v-card-actions class=" py-2">
            <v-spacer />
            <v-btn block  @click="askGPT" class="cyan rounded-lg" height="70">
              <span class=" text-h5 mt-2 ml-2  paper--text">
                ACTIVATE AI OVERLORD
              </span>
            </v-btn>
          </v-card-actions>
        </template>
        <v-progress-linear
          color="cyan"
          indeterminate
          rounded
          height="1"
          class="my-2"
          v-if="step > 0 && step != 4"
        />
        <template v-if="step > 0 && step != 4">
          <v-card-actions v-if="step > 0" class="text-center">
            <v-spacer />
            <vue-typed-js v-if="step == 1" :strings="messages_exercise">
              <h6 class="typing progressActive--text"></h6>
            </vue-typed-js>
            <vue-typed-js v-if="step == 2" :strings="messages_muscles">
              <h6 class="typing progressActive--text"></h6>
            </vue-typed-js>
            <vue-typed-js v-if="step == 3" :strings="messages_equipment">
              <h6 class="typing progressActive--text"></h6>
            </vue-typed-js>
            <v-spacer />
          </v-card-actions>
        </template>
        <template v-if="step == 0 && !found && processed">
          <v-card-actions class=" py-4">
            <v-spacer />
            <v-img
              src="@/assets/SurprisedBrother.png"
              :width="isPhone ? '220px' : '150px'"
              contain
              alt="Bruh-AI Assistant"
            />
            <v-spacer />
          </v-card-actions>
          <v-card-text class="silver--text text-h6 text-center">
            This exercise was not found.<v-spacer />
            Please try another exercise.
          </v-card-text>
        </template>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak,
  HorizontalRule
} from 'tiptap-vuetify'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'
import tooltips from '@/json/tooltips.json'
import sounds from '@/json/sounds.json'
export default {
  components: { TiptapVuetify },
  mixins: [validationMixin, util],
  props: {
    exercise_name: String
  },
  data: () => ({
    audio: null,
    aiDone: null,
    aiError: null,
    loaded: true,
    processed: false,
    step: 0,
    sounds: sounds,
    found: false,
    tooltips: tooltips,
    exercise: {
      name: '',
      description: '' // Assuming you also have a description field to validate
    },
    theforces: [],
    mechanics: [],
    utility: [],
    mechanicsType: {},
    utilityType: {},
    theforceType: {},
    name: '',
    alternate_name: '',
    oldname: '',
    dificulty: '',
    description: '',
    instructions: '',
    muscles: [],
    primary: [],
    secondary: [],
    stabilizers: [],
    equipment: [],
    musclesList: [],
    equipmentList: [],
    dificultyList: [],
    glossary: {},
    showSuccess: false,
    switchNum: 0,
    extensions: [
      Paragraph,
      Bold,
      Italic,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3, 4, 5, 6]
          }
        }
      ],
      HorizontalRule,
      Link,
      ListItem,
      BulletList,
      OrderedList,
      HardBreak
    ],
    messages_muscles: [
      'Identifying exercise muscles',
      'Classifying muscles.',
      'Setting Primary & Secondary Muscles.',
      'Setting Stabilizer Muscles.',
      'Performing final checks.'
    ],
    messages_exercise: [
      'Analyzing request.',
      'Finding exercise information.',
      'Generating exercise instructions.',
      'Identifying forces and utility.',
      'Assembling exercise components.'
    ],
    messages_equipment: [
      'Identifying needed equipment',
      'Validating exercise information'
    ]
  }),
  beforeMount() {
    this.audio = new Audio(this.sounds['bruh'])
    this.aiDone = new Audio(this.sounds['ai-done'])
    this.aiError = new Audio(this.sounds['ai-error'])
    this.audio.preload = 'auto'
    this.aiError.preload = 'auto'
    this.aiDone.preload = 'auto'
    this.loadGlosary()
    this.loadEquipment()
    this.loadMusclesDefinition()
  },
  mounted() {
    this.found = false
    this.step = 0
  },
  validations: {
    exercise: {
      name: { required },
      description: { required } // Include this line if you also want to validate the description
    }
  },
  computed: {
    canEditExercise() {
      return this.xvy || this.exercise.author == this.userid
    },
    nameErrors() {
      const errors = []
      // Update path to reference exercise.name
      if (!this.$v.exercise.name.$dirty) return errors
      !this.$v.exercise.name.required &&
        errors.push('Exercise name is required.')
      return errors
    },

    descriptionErrors() {
      const errors = []
      // Update path to reference exercise.description
      if (!this.$v.exercise.description.$dirty) return errors
      !this.$v.exercise.description.required &&
        errors.push('Description is required.')
      return errors
    },
    ...appConfig
  },
  methods: {
    calculateCode() {
      this.$v.exercise.name.$touch()
      this.exercise.code = this.generateCode(this.exercise.name)
    },
    tileColor(selected) {
      return selected ? 'charcoal charcoalTileMenu' : 'charcoal'
    },
    textColor(selected) {
      return selected ? 'paper--text' : 'silver--text'
    },
    iconColor(selected) {
      return selected ? 'paper' : 'silver'
    },
    addMore() {
      this.showSuccess = false
      this.$v.$reset()
      this.$refs.name.focus()
    },
    settheforce(what) {
      this.theforceType = what
    },
    setMechanics(what) {
      this.mechanicsType = what
    },
    setUtility(what) {
      this.utilityType = what
    },
    saveExercise() {
      this.step = 0
      let equipment = this.equipment.map(equipment => equipment.id)
      return axios
        .post(this.baseURL + '/exercises', {
          name: this.exercise.name,
          dificulty: this.exercise.dificulty,
          description: this.exercise.description,
          instructions: this.exercise.instructionsStr,
          secondary: this.secondary,
          stabilizers: this.stabilizers,
          equipment: equipment,
          theforce: this.exercise.theforce,
          mechanics: this.exercise.mechanics,
          utility: this.exercise.utility,
          resistance: this.exercise.resistance,
          bodyweight: this.exercise.bodyweight,
          track_distance: this.exercise.track_distance,
          track_time: this.exercise.track_time,
          cardio: this.exercise.cardio,
          calisthenics: this.exercise.calisthenics,
          bilateral: this.exercise.bilateral,
          public: this.exercise.ispublic,
          code: this.exercise.code
        })
        .then(response => {
          if (response.status == 200) {
            this.toast(
              'Exercise ' + this.exercise.name + ' was succesfully added.'
            )

            this.exercise = null
            this.$router.push('/exercises')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadMusclesDefinition() {
      {
        return axios
          .get(this.baseURL + '/util/muscles/all', {})
          .then(response => {
            if (response.status == 200) {
              this.musclesList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadEquipment() {
      {
        return axios
          .get(this.baseURL + '/util/equipment/all', {})
          .then(response => {
            if (response.status == 200) {
              this.equipmentList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadGlosary() {
      return axios
        .get(this.baseURL + '/util/glossary/all', {})
        .then(response => {
          if (response.status == 200) {
            this.glossary = response.data.data

            this.theforces = this.glossary.filter(function(item) {
              return item.category == 'Force'
            })
            this.mechanics = this.glossary.filter(function(item) {
              return item.category == 'Mechanics'
            })
            this.utility = this.glossary.filter(function(item) {
              return item.category == 'Utility'
            })
            this.dificultyList = this.glossary.filter(function(item) {
              return item.category == 'Difficulty'
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    askGPT() {
      this.found = false

      this.step = 1
      this.loaded = false

      let cfg = {
        method: 'post',
        url: this.baseURL + '/exercises/autofind',
        data: {
          exercise: this.exercise_name
        }
      }
      return axios(cfg).then(response => {
        this.processed = true
        if (response.status == 200) {
          const jsonContent = this.extractJsonContent(response)
          this.exercise = JSON.parse(jsonContent)
          this.found = this.exercise.found != false
          if (this.found) {
            this.audio.play()
            let instructions = this.exercise.instructions
            let html = ''
            for (const [title, steps] of Object.entries(instructions)) {
              html += `<h6>${title}</h6>\n`
              html += '<ol>\n'
              // Check if steps is an array, if not, treat it as a single step in an array
              const stepsArray = Array.isArray(steps) ? steps : [steps]
              for (const step of stepsArray) {
                html += `  <li>${step}</li>\n`
              }
              html += '</ol>\n'
            }

            this.exercise.instructionsStr = html
            this.exercise.code = this.generateCode(this.exercise.name)
            this.exercise.theforce = this.exercise.force
            this.exercise.track_distance = 'No'
            this.exercise.track_time = 'No'
            this.exercise.olympic = 'No'
            this.exercise.powerlifting = 'No'
            this.exercise.ispublic = 'Yes'

            this.getMuscles()
          } else {
            this.step = 0
            this.aiError.play()
          }
        } else {
          return
        }
      })
    },

    getMuscles() {
      this.step = 2
      const musclesString = JSON.stringify(
        this.musclesList.map(obj => ({ id: obj.id, name: obj.name }))
      )
      let cfg = {
        method: 'post',
        url: this.baseURL + '/exercises/autofind/muscles',
        data: {
          exercise: this.exercise_name,
          muscles: musclesString
        }
      }
      return axios(cfg).then(response => {
        if (response.status == 200) {
          const jsonContent = this.extractJsonContent(response)
          this.muscles = JSON.parse(jsonContent)
          this.primary = this.muscles
            ? this.muscles.filter(function(item) {
                return item.type == 'Primary'
              })
            : null

          this.secondary = this.muscles
            ? this.muscles.filter(function(item) {
                return item.type == 'Secondary'
              })
            : null

          this.stabilizers = this.muscles
            ? this.muscles.filter(function(item) {
                return item.type == 'Stabilizers'
              })
            : null

          this.primary = this.primary.map(muscle => muscle.id)
          this.secondary = this.secondary.map(muscle => muscle.id)
          this.stabilizers = this.stabilizers.map(muscle => muscle.id)
        } else {
          return
        }
        this.getEquipment()
      })
    },
    getEquipment() {
      this.aiDone.play()
      this.step = 3

      let cfg = {
        method: 'post',
        url: this.baseURL + '/exercises/autofind/equipment',
        data: {
          exercise: this.exercise_name
        }
      }
      return axios(cfg).then(response => {
        if (response.status == 200) {
          const jsonContent = this.extractJsonContent(response)
          const terms = JSON.parse(jsonContent)

          const matchedItems = this.equipmentList.filter(item => {
            // convert item name and search terms to lowercase for case-insensitive matching
            const itemName = item.name.toLowerCase()
            const searchTerms = terms.map(term => term.name.toLowerCase())

            // check if any of the search terms is included in the item name
            return searchTerms.some(term => itemName.includes(term))
          })
          this.equipment = matchedItems

          this.loaded = true
        } else {
          return
        }
        this.step = 4
        this.loaded = true
      })
    }
  }
}
</script>
<style scoped>
.vue-typer {
  font-family: monospace;
}

.vue-typer .custom.char {
  color: #d4d4bd;
  background-color: #1e1e1e;
}
.vue-typer .custom.char.selected {
  background-color: #264f78;
}

.vue-typer .custom.caret {
  width: 10px;
  background-color: #3f51b5;
}
</style>
